import { Box } from '@mui/system';
import './index.scss';
import OtpLogic from "./OtpLogic";
import { VERIFICATION_CODE_LENGTH } from "../../../helpers/constants";

const OtpInput = ({ customBackground, code, setCode, isValidCode, onVerifyEmail }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        border: 'none'
      }}
    >
      <OtpLogic
        value={code}
        onChange={setCode}
        length={VERIFICATION_CODE_LENGTH}
        customBackground={customBackground}
        isValidCode={isValidCode}
        onVerifyEmail={onVerifyEmail}
      />
    </Box>
  );
}

export default OtpInput;