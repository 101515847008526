import React, { useContext, useEffect, useCallback, useState } from "react";
import Context from "../../context";
import { db } from "../../db";
import { getUserInformation, logInUser, submitItineraryLoginList } from "../../libraries/api";
import Spinner from "../global/Spinner";
import { useNavigate, } from "react-router-dom";
import './SignIn.scss';
import { v4 as uuidv4 } from 'uuid';
import SignInGoogle from "./SignInGoogle";
import SignInApple from "./SignInApple";
import SignInCustom from "./SignInCustom";
import { useAnalytics } from "../GoogleAnalytics";
import { logEvent } from "firebase/analytics";

const SignIn = () => {
  const {
    composerData,
    setIsUserAuthenticated,
    setGlobalModalContent,
    t,
    setItineraryUpdate,
    setUserAuthInfo,
    setUserJustSignIn,
    setLastUserAuthInfoRequestTime,
    setCustomAuthParams,
    setIsCustomAuth,
    setItin
  } = useContext(Context);
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const [spinner, setSpinner] = useState(false);

  let state = localStorage.getItem('state');
  if (!state) {
    state = uuidv4();
    localStorage.setItem('state', state);
  }

  let nonce = localStorage.getItem('nonce');
  if (!nonce) {
    nonce = uuidv4();
    localStorage.setItem('nonce', nonce);
  }

  const handleSignIn = useCallback(async (loginId, logInUserBody, args) => {
    try {
      let userData;
      const { res, data, error } = await logInUser(logInUserBody);
      if (!res.ok || error || !data?.key) {
        // eslint-disable-next-line no-throw-literal
        throw { modalMessage: error ?? ((data?.error?.length && data?.error[0]?.message) || data?.error) ?? t("error_auth") };
      }

      userData = data.user;

      await db.session.add({
        key: data.key,
        gId: loginId === 'google' ? args : null
      });

      const storedItins = await db.itineraries
        .orderBy('lastLogin')
        .reverse()
        .toArray();

      const buildDateString = timestamp => {
        const date = new Date(timestamp)
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
      };

      //get non nested itineraries from indexedDb
      const storedNonNestedItins = storedItins.filter(itin => itin.parentVamoosId === null);

      //form itinerary list if any itineraries in the indexedDb
      let itineraryList = storedNonNestedItins.map((itin) => {
        const { type, client, operatorCode, referenceCode, vamoosId } = itin;

        if (type === 'trip') {
          //Import friendlyName only initially if the itinerary is not in the logins list.
          const foundItinerary = data?.user?.logins.all.find(login => login.id === vamoosId);
          if (foundItinerary) {
            //remove client information from the localData so that we can hide the friendlyNameSetModal in the userSelect component if user does not specify friendlyName again
            setItin(prevItin => {
              const updatedLocalData = { ...prevItin?.localData };
              delete updatedLocalData.client;
              return { ...prevItin, localData: updatedLocalData };
            });
          }

          const friendlyName = (client && !foundItinerary) ? client.friendlyName : null;
          return { operatorCode, referenceCode, ...(friendlyName && { friendlyName }) };
        } else if (type === 'stay') {
          const { arrivalDate, departureDate, bookingName, email } = itin;
          const meta = {
            ...(arrivalDate && { arrivalDate: buildDateString(arrivalDate) }),
            ...(departureDate && { departureDate: buildDateString(departureDate) }),
            ...(bookingName && { bookingName }),
            ...(email && { email }),
          };
          return { operatorCode, referenceCode, meta };
        } else if (type === 'inspiration') {
          return { operatorCode, referenceCode }
        }
        return null;
      });

      if (itineraryList.length !== 0) {
        const {
          res: submitItinListRes,
          data: submitItinListData,
          error: submitItinListError
        } = await submitItineraryLoginList({ logins: itineraryList });
        if (!submitItinListRes.ok || submitItinListError) {
          // eslint-disable-next-line no-throw-literal
          throw { modalMessage: submitItinListError || submitItinListData?.error[0]?.message || submitItinListData?.error || t("auth_error_submit_login_list") };
        }

        const { res: userInfoRes, data: userInfoData, error: userInfoError } = await getUserInformation();
        if (!userInfoRes.ok || userInfoError) {
          // eslint-disable-next-line no-throw-literal
          throw { modalMessage: userInfoError || userInfoData?.error[0]?.message || userInfoData?.error || t("auth_error_get_profile_info") };
        }
        userData = userInfoData;
      }
      localStorage.setItem("userEmail", userData.primaryEmail);
      setUserAuthInfo(userData);
      setIsUserAuthenticated(true);
      setLastUserAuthInfoRequestTime(Date.now());
      setUserJustSignIn(true);

      if (!!analytics) logEvent(analytics, 'sso_login_completed', { vamoos_count: userData?.logins?.all?.length, login_id: loginId });

      if (userData.logins.all.length === 0) {
        //if there are no logins for this auth user, navigate to login screen and show welcome screen
        navigate(`/login/1`, { replace: true });
      } else {
        if (storedNonNestedItins?.length > 1) {
          navigate(`/${storedNonNestedItins[0].urlKey}/view-all`);
        } else {
          setItineraryUpdate(userData.logins.default);
        }
      }
    } catch (e) {
      if (!!analytics) logEvent(analytics, 'sso_login_failed', { login_id: loginId });
      console.error(e);
        setGlobalModalContent(() => ({
          closable: true,
          message: e.modalMessage|| e?.message || t("error_auth"),
          buttons: [{ text: t('ok'), type: 'close' }]
        }));
    } finally {
      localStorage.removeItem("state");
      localStorage.removeItem("nonce");
      localStorage.setItem('loginId', loginId);

      if (loginId !== 'google' && loginId !== 'apple') {
        setCustomAuthParams(null);
        setIsCustomAuth(false);
      }
      setSpinner(false);
    }
  }, [analytics, setItin, setCustomAuthParams, setIsCustomAuth, navigate, setGlobalModalContent, setIsUserAuthenticated, setItineraryUpdate, setLastUserAuthInfoRequestTime, setUserAuthInfo, setUserJustSignIn, t])

  useEffect(() => {
    if (spinner) {
      return () => {
        setSpinner(false);
      };
    }
  }, [spinner]);

  return (
    <div className="sign-in-buttons">
      {spinner
        ? <Spinner type="small" />
        : <>
          {
            composerData?.auth?.hasOwnProperty("google") &&
            <SignInGoogle handleSignIn={handleSignIn} setSpinner={setSpinner} nonce={nonce} />
          }
          {
            composerData?.auth?.hasOwnProperty("apple") &&
            <SignInApple handleSignIn={handleSignIn} setSpinner={setSpinner} state={state} nonce={nonce} />
          }
          {
            composerData.hasOwnProperty("customAuthName") &&
            <SignInCustom handleSignIn={handleSignIn} setSpinner={setSpinner} state={state} nonce={nonce}
                          customAuthName={composerData.customAuthName}
            />
          }
        </>
      }
    </div>
  )
}

export default SignIn;
