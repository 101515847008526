import React, { useContext, useRef, useEffect, useState } from 'react';
import Context from '../../context';
import './SlideTemplate.scss';
import Button from '../global/Buttons/Button';
import TextInput from "../global/TextInput";
import SignIn from "./SignIn";
import AddEmailModal from "../Settings/AddEmailModal";

const SlideTemplate = ({
  className,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  inputList,
  skipFn,
  button: { disabled, text },
  isActive,
  submitFn,
  hideContent,
  logoOverride,
  id,
  loading,
  setIsLoading,
  showCustomTemplate,
  handleRetryClick,
  authRequiredToLogin,
}) => {
  const { t, composerData, isUserAuthenticated, viewMode, setItineraryUpdate } = useContext(Context);
  const [openAddEmailModal, setOpenAddEmailModal] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      // TODO: this is causing the login screen margin to load incorrectly for stays.
      // userid => passcode => should go to stays personal info screen. Instead goes to stays dates screen.
      // The margin is the correct value required for the stays personal info screen.
      // Commenting out the below line makes it behave as it should.
      // inputRef.current.focus();
      // inputRef.current.style.border = '5px solid red';
    }
  }, [id, isActive]);

  const onInputChange = (val, cb) => {
    setItineraryUpdate(null);
    cb(val.replace(/^\s+/g, ''));
  };

  const placeholderText = <>placeholder<br />placeholder</>;

  const renderInputs = () => (
    inputList.map(({ value, onChange, placeholder, className, type, autoFocus }, i) => (
      <TextInput
        key={i}
        value={value}
        onChange={e => onInputChange(e.target.value, onChange)}
        placeholder={placeholder}
        className={`login-input ${className}`}
        type={type}
        onKeyUp={e => {
          if (!isActive || disabled) return;
          if (e.code === 'Enter') submitFn();
        }}
        ref={i === 0 ? inputRef : null}
        autoFocus={autoFocus === true}
      />
    ))
  );

  const renderDefaultTemplate = () => {
    return (
      <>
        {inputList && !authRequiredToLogin && renderInputs()}
        <div
          className={`text text-4 ${!text4 ? 'invisible-text' : ''}`}
          dangerouslySetInnerHTML={{ __html: text4 || placeholderText }}
        />
        {!authRequiredToLogin && <Button disabled={disabled} onClick={() => submitFn()}>{text}</Button>}
        {
          skipFn && <button onClick={() => skipFn()} className="skip">{t('login_skip_button')}</button>
        }
        <div
          className={`text text-5 ${!text5 ? 'invisible-text' : ''}`}
          dangerouslySetInnerHTML={{ __html: text5 || placeholderText }}
        />
      </>
    )
  };

  const renderCustomTemplate = () => {
    return (
      <>
        <Button
          onClick={() => setOpenAddEmailModal(true)}
        >
          {t('add_email_to_user_profile')}
        </Button>
        <div
          className={`text text-5 custom-template ${!text5 ? 'invisible-text' : ''}`}
          dangerouslySetInnerHTML={{ __html: text5 || placeholderText }}
        />
        {!authRequiredToLogin && <div
          className="text text-6 custom-template"
          dangerouslySetInnerHTML={{ __html: text6 || placeholderText }}
        />}
        {inputList && !authRequiredToLogin && renderInputs()}
        {!authRequiredToLogin && <Button disabled={disabled} onClick={() => submitFn()}>{text}</Button>}
        <div
          className={`text text-7 custom-template`}
          dangerouslySetInnerHTML={{ __html: text7 || placeholderText }}
        />
        {
          <AddEmailModal
            openModal={openAddEmailModal}
            setOpenAddEmailModal={setOpenAddEmailModal}
            setSpinner={setIsLoading}
            spinner={loading}
            finalStep="step2"
          />
        }
      </>
    )
  };

  return (
    <div id={id} className={`login-slide ${className} ${hideContent ? 'hidden' : ''} ${showCustomTemplate ? 'custom-template' : ''}`}>
      <img className="logo" src={logoOverride || composerData?.images?.logo?.filePath || '/images/vamoos.png'} alt="" />
      <div className={`text-container-top ${showCustomTemplate ? 'custom-template' : ''}`}>
        <div
          className={`text text-1 ${!text1 ? 'invisible-text' : ''} ${showCustomTemplate ? 'custom-template' : ''}`}
          dangerouslySetInnerHTML={{ __html: text1 || placeholderText }}
        />
        <div
          className={`text text-2 ${!text2 ? 'invisible-text' : ''} ${showCustomTemplate ? 'custom-template' : ''}`}
          dangerouslySetInnerHTML={{ __html: text2 || placeholderText }}
        />
        {showCustomTemplate
          ? <div className={`text text-3 ${!text3 ? 'invisible-text' : ''}`}>
            {text3}
            <button className='link' onClick={handleRetryClick}>{viewMode === "mobile" ? t('welcome_screen_step1_clickable_text') : t('welcome_screen_step1_clickable_text_desktop')}</button>
          </div>
          : <div
            className={`text text-3 ${!text3 ? 'invisible-text' : ''}`}
            dangerouslySetInnerHTML={{ __html: text3 || placeholderText }}
          />
        }
        {showCustomTemplate &&
          <div
            className={`text text-4 ${!text4 ? 'invisible-text' : ''} ${showCustomTemplate ? 'custom-template' : ''}`}
            dangerouslySetInnerHTML={{ __html: text4 || placeholderText }}
          />
        }
      </div>
      {showCustomTemplate ? renderCustomTemplate() : renderDefaultTemplate()}
      {className === "user-id-screen" && (
        <>
          {!isUserAuthenticated && (
            <>
              <SignIn />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SlideTemplate;
