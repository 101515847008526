import { useContext } from 'react';
import { db } from "../db";
import Context from "../context";
import { useAnalytics } from "../components/GoogleAnalytics";
import { logEvent } from "firebase/analytics";

const goggleRevoke = (googleId, composerData) => {
  //to have client_id for the revoke method
  window.google.accounts.id.initialize({
    client_id: composerData?.auth?.google.systemClientId,
  });

  window.google.accounts.id.disableAutoSelect();
  window.google.accounts.id.revoke(googleId, done => {
    console.log('done.error', done.error);
    console.log('done', done);
  });
}

export default function useSignOut(type) {
  const {
    setLoginDetails,
    setIsUserAuthenticated,
    setItineraryUpdate,
    setUserAuthInfo,
    setIsLoggingOut,
    setCustomHistory,
    setNavMenuItems,
    composerData,
    setLoginPrompt,
  } = useContext(Context);
  const analytics = useAnalytics();

  return async () => {
    if (!!analytics) {
      const eventType = type === 'manual' ? 'sso_logout' : 'sso_logout_automatic';
      logEvent(analytics, eventType, { login_id: localStorage.getItem("loginId") });
    }

    if (type !== 'error') {
      const user = await db.session.toArray();
      const googleId = user[0]?.gId;
      if (googleId !== null) goggleRevoke(googleId, composerData);
    }

    localStorage.removeItem("userEmail");
    localStorage.removeItem("state");
    localStorage.removeItem("nonce");
    localStorage.removeItem("loginId");
    await db.session.clear();
    await db.itineraries.clear();
    setItineraryUpdate(null);
    setLoginPrompt(null);
    setIsUserAuthenticated(false);
    setUserAuthInfo(null);
    setLoginDetails(null);
    setCustomHistory([]);
    setNavMenuItems(null);
    setIsLoggingOut(false);
  };
};