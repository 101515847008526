import { useContext } from 'react';
import Context from '../../context';
import './StaysPersonalInfoScreen.scss';
import SlideTemplate from './SlideTemplate';
import { isValidEmail } from "../../helpers";

const StaysPersonalInfoScreen = ({ staysPersonalInfo, setStaysPersonalInfo, skippable, skipFn, isActive, logoOverride, id, submitFn }) => {
  const { t, composerData } = useContext(Context);

  return (
    <SlideTemplate
      className="stays-personal-info-screen"
      text3={t('login_form_name_header_text')}
      inputList={[
        {
          className: "name-input",
          value: staysPersonalInfo.name,
          onChange: val => setStaysPersonalInfo(staysPersonalInfo => ({
            ...staysPersonalInfo,
            name: val
          })),
          placeholder: t('login_form_name_hint'),
          autoFocus: true,
        },
        {
          className: "email-input",
          value: staysPersonalInfo.email,
          type: 'email',
          onChange: val => setStaysPersonalInfo(staysPersonalInfo => ({
            ...staysPersonalInfo,
            email: val
          })),
          placeholder: t('login_form_email_hint')
        }
      ]}
      button={{
        disabled: !staysPersonalInfo.name || !isValidEmail(staysPersonalInfo.email),
        text: composerData?.strings?.login_next_button?.text || t('login_next_button'),
      }}
      skipFn={skippable ? skipFn : false}
      text5={t('login_form_name_footer_text')}
      isActive={isActive}
      submitFn={submitFn}
      logoOverride={logoOverride}
      id={id}
    />
  );
};

export default StaysPersonalInfoScreen;
