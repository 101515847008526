import Modal from './index';
import './ModalDefault.scss';
import Spinner from "../Spinner";

const ModalDefault = ({ header, message, buttons, children, className, loading, ...props }) => {
  return (
    <Modal {...props}>
      <div className={`modal-body ${className ? className : ''}`}>
        { header && <div className="header">{header}</div> }
        { message && <div className="message">{message}</div> }
        { children }
        {
          (buttons?.length) && <div className="buttons">{buttons}</div>
        }
        {loading && (
          <div className="center-spinner">
            <Spinner />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalDefault;