import { useContext, forwardRef } from 'react';
import Context from '../../../context';
import './index.scss';

const TextInput = forwardRef(({ className, innerRef, translucentBackground, customBackground, ...props }, ref) => {
  const { composerData } = useContext(Context);

  return (
    <input
      className={`text-input ${className ? className : ''} ${composerData?.settings?.input_field_square_corners ? 'input-field-square-corners' : ''} ${translucentBackground ? 'translucent-background' : ''} ${customBackground ? 'custom-background' : ''}`}
      type="text"
      ref={ref || null}
      {...props}
    />
  );
});

export default TextInput;