import appConfig from "../config/app";
import { getLanguages } from "../helpers";
import { db } from "../db";
import { VERSION_ID, PLATFORM } from "../helpers/constants"

export const request = async (url, options = {}, useVamoosHeaders = false, useApiKey = true) => {
  if (useVamoosHeaders) {
    const apiKey = useApiKey && (await db.session.toArray())[0]?.key;
    options.headers = {
      "User-Agent": 'WebVamoos/3.0.0',
      "x-vamoos-user-info": JSON.stringify({"version_id":VERSION_ID,"platform":PLATFORM, bundle_id: localStorage.getItem('bundleId')}),
      "accept-language": getLanguages().join(','),
      ...(apiKey && { "X-User-Access-Token": apiKey }),
      ...options?.headers
    };
  }

  const out = {};
  try {
    out.res = await fetch(url, options);

    if (out.res.headers.get("content-type").includes("application/json")) {
      out.data = await out.res.json();
    } else if (out.res.headers.get("content-type").includes("image")) {
      out.data = await out.res.arrayBuffer();
    } else if (out.res.headers.get("content-type").includes("text")) {
      out.data = await out.res.text();
    } else {
      out.data = null;
    }

  } catch (e) {
    out.error = e;
  }
  return out;
};

export const getItinerary = async (operatorCode, passcode, phase = 'refresh', nested = 'false', travellerId = null) => {
  let url = appConfig.apiHost + `/api/itineraries/${operatorCode}-${passcode}?phase=${phase}&nested=${nested}`;
  if (travellerId && phase === 'refresh') url += `&traveller_id=${travellerId}`;
  return await request(url, undefined, true);
};

export const getMultipleItineraries = async (operatorCodes, passcodes) => {
  const requests = operatorCodes.map((operatorCode, index) =>
    getItinerary(operatorCode, passcodes[index], 'refresh', 'true')
  );
  return await Promise.all(requests);
};

export const getPreviewItinerary = async (previewLinkId) => {
  const url = appConfig.apiHost + `/api/preview/${previewLinkId}`;
  return await request(url, undefined, true, false);
};

export const getOperatorType = async operatorCode => {
  const url = appConfig.apiHost + `/api/itineraries/operator_type/${operatorCode}`;
  return await request(url, undefined, true);
};

export const sendDownloadConfirmation = async (operatorCode, passcode, body) => {
  const url = appConfig.apiHost + `/api/itineraries/${operatorCode}-${passcode}/download_confirmation`;
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const sendDeviceInformation = async (body, isNested) => {
  const url = appConfig.apiHost + `/api/devices?nested=${isNested.toString()}`;
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const setDeviceTimezone = async (body, deviceToken) => {
  const url = `${appConfig.apiHost}/api/devices/${deviceToken}/set_timezone`;
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};


export const getStaticComposerData = async hostname => {
  let profile = 'dev';
  if (['live', 'production'].includes(process.env.REACT_APP_ENV)) profile = 'live';
  else if (['beta', 'uat'].includes(process.env.REACT_APP_ENV)) profile = process.env.REACT_APP_ENV;

  return await request(`${process.env.PUBLIC_URL}/sites/${profile}/${hostname}/composerData.json`);
};

export const submitPosts = async (body, operatorCode, referenceCode, travellerId = null) => {
  let url = appConfig.apiHost + `/api/itineraries/${operatorCode}-${referenceCode}/journal`;
  if (travellerId) url += `?traveller_id=${travellerId}`;
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const logInUser = async body => {
  const url = appConfig.apiHost + `/app/auth/login`;
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const logOutUser = async () => {
  const url = appConfig.apiHost + `/app/auth/logout`;
  const options = {
    method: 'POST',
  };
  return await request(url, options, true);
};

export const getUserInformation = async () => {
  const url = appConfig.apiHost + `/app/auth/profile`;
  return await request(url, undefined, true);
};

export const removeItineraryFromLoginList = async body => {
  const url = appConfig.apiHost + `/app/auth/login_list`;
  const options = {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const submitItineraryLoginList = async body => {
  const url = appConfig.apiHost + `/app/auth/login_list`;
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const updateTravellerName = async (body, operatorCode, referenceCode, travellerId = null) => {
  let url = appConfig.apiHost + `/app/itineraries/${operatorCode}-${referenceCode}/clients`;
  if (travellerId) url += `?traveller_id=${travellerId}`;
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const addUserEmail = async body => {
  const url = appConfig.apiHost + `/app/auth/email_add_request`;
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const verifyUserEmail = async body => {
  const url = appConfig.apiHost + `/app/auth/email_add_verify`;
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const deleteUserEmail = async body => {
  const url = appConfig.apiHost + `/app/auth/email`;
  const options = {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};

export const deleteUserAccount = async () => {
  const url = appConfig.apiHost + `/app/auth/user`;
  const options = {
    method: 'DELETE',
  };
  return await request(url, options, true);
};

export const shareItinerary = async (operatorCode, passcode, body) => {
  const url = appConfig.apiHost + `/app/itineraries/${operatorCode}-${passcode}/share`;
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return await request(url, options, true);
};