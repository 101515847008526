import Modal from './index';
import './ModalCustom.scss';
import { useContext } from "react";
import Context from "../../../context";
import Spinner from "../Spinner";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ModalCustom = ({
                       header,
                       content1,
                       content2,
                       confirmButton,
                       cancelButton,
                       children,
                       className,
                       onClose,
                       loading,
                       showBackButton,
                       backButtonPath,
                       backButtonFn,
                       ...props
                     }) => {
  const { viewMode } = useContext(Context);
  const headerPlaceholder = <div className="header invisible-text">header placeholder</div>;
  const content1Placeholder = <div className="content-1 invisible-text">content placeholder</div>;

  return (
    <Modal {...props}>
      <div className={`modal-custom-body ${className || ''}`}>
        {viewMode === "mobile" &&
          <div className="mobile-header">
            <IconButton
              className={`back-button ${showBackButton ? "" : "invisible"}`}
              aria-label="Go back"
              component={Link}
              to={backButtonPath}
              onClick={backButtonFn}
            >
              <ArrowBackIcon className="back-button-icon" />
            </IconButton>
          </div>
        }
        <div className={`modal-custom modal-custom-container-${viewMode}`}>
          {
            viewMode === 'desktop' && <img className="close" src="/icons/exit.svg" alt="" onClick={onClose} />
          }
          {header ? <div className="header">{header}</div> : headerPlaceholder}
          {content1 ? <div className="content-1">{content1}</div> : content1Placeholder}
          {content2 && <div className="content-2">{content2}</div>}
          {children}
          {confirmButton && <div className="confirm-button">{confirmButton}</div>}
          {cancelButton && viewMode === 'mobile' && <div className="cancel-button">{cancelButton}</div>}
        </div>
        {loading && (
          <div className="center-spinner">
            <Spinner />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalCustom;