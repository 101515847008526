import { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import './index.scss';
import Context from '../../../context';
import { db } from "../../../db";
import { getUserInformation, logOutUser } from "../../../libraries/api";
import LoadingScreen from "../LoadingScreen";
import { getDeletedItineraryIds, getNewCurrentItinerary, setMenuTitle } from "../../../helpers";
import Spinner from "../Spinner";
import useSignOut from '../../../hooks/useSignOut';

const HamburgerMenu = ({ hamburgerBtnClassName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [storedNonNestedItineraries, setStoredNonNestedItineraries] = useState([]);
  const [showedItineraries, setShowedItineraries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);

  const {
    t,
    itin, setItin,
    setLoginDetails,
    viewMode,
    composerData,
    setGlobalModalContent,
    isUserAuthenticated,
    setUserAuthInfo,
    setIsLoggingOut,
    userAuthInfo,
    userJustSignIn,
    isUpdatingUserAuthInfo,
    loginPrompt,
    setCustomHistory,
    setUserJustSignIn,
    setLastUserAuthInfoRequestTime,
    key,
    itemOfHamburgerMenu,
    setNavMenuItems,
  } = useContext(Context);

  const navigate = useNavigate();
  const signOutError = useSignOut('error');
  const signOutManual = useSignOut('manual');

  useEffect(() => {
    if (isUpdatingUserAuthInfo) return;
    const getStoredNonNestedItineraries = async () => {
      const storedItineraries = await db.itineraries
        .orderBy('lastLogin')
        .reverse()
        .toArray();
      const filteredStoredItineraries = storedItineraries.filter(x => x.parentVamoosId === null || !x.parentVamoosId)
      setStoredNonNestedItineraries(filteredStoredItineraries);
    };

    getStoredNonNestedItineraries();
  }, [isUpdatingUserAuthInfo, itin, isOpen]);

  useEffect(() => {
    if (isUserAuthenticated && !userAuthInfo?.logins?.all) return;
    if (loginPrompt === 'loadingFailed') {
      setShowedItineraries([]);
      return;
    }
    let isActive = true;
    let allItins;

    if (isUserAuthenticated) {
      allItins = [...userAuthInfo?.logins?.all];
    } else {
      allItins = [...storedNonNestedItineraries];
    }
    if (isActive) setShowedItineraries(allItins);

    return () => {
      isActive = false;
    };
  }, [loginPrompt, isUserAuthenticated, itin?.localData.lastLogin, userAuthInfo?.logins?.all, isOpen, storedNonNestedItineraries]);

  useEffect(() => () => setIsLoading(false), []);

  const toggleMenu = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift' || event.key === 'Enter')
    ) {
      return;
    }
    if (open) setUserJustSignIn(false);
    setIsOpen(open);
    setAnchorEl(open ? event.currentTarget : null);
  };

  const renderMenu = () => {
    const topMenu = [
      { title: t('menu_refresh'), type: 'refresh' },
      { title: t('settings'), path: itin ? `/${itin?.localData.urlKey}/settings` : '/settings' }
    ];
    const itineraryMenu = [{ title: t('menu_load_new'), path: '/login' }];
    const signOutMenu = [{ title: t('menu_sign_out'), type: 'sign-out' }];
    if (showedItineraries.length >= 1) {
      //to set most current itinerary first
      let reorderedItineraries = [...showedItineraries];

      if (isUserAuthenticated) {
        const targetIndex = showedItineraries.findIndex(item => userJustSignIn
          ? storedNonNestedItineraries.length === 0 ? item.id === userAuthInfo?.logins?.default?.id : item.id === storedNonNestedItineraries[0]?.vamoosId
          : item.id === storedNonNestedItineraries[0]?.vamoosId);
        if (targetIndex > -1) {
          const targetItem = reorderedItineraries.splice(targetIndex, 1)[0];
          reorderedItineraries.unshift(targetItem);
        }
      }

      const otherItineraries = reorderedItineraries
        .slice(1, 9)
        .map(x => ({
          title: x.menuTitle ?? setMenuTitle(x),
          path: x.vamoosId ? `/${x.vamoosId}` : `/${x.id}`,
          type: 'itinerary',
          operatorCode: x.operatorCode,
          passcode: x.passcode || x.referenceCode,
          clientId: isUserAuthenticated
            ? storedNonNestedItineraries.find(itin => itin.vamoosId === (x.vamoosId || x.id))?.client?.id
            : x.client?.id,
          vamoosId: x.vamoosId ?? x.id,
        }));
      //to show view-all without urlKey if itin not found
      itineraryMenu.push(...otherItineraries, {
        title: t("menu_view_all"),
        path: `${itin ? `/${itin?.localData.urlKey}/view-all` : `/view-all`}`
      });
    }

    let menuItems

    menuItems = [
      topMenu,
      itineraryMenu,
      [
        { title: t('faq'), path: itin ? `/${itin?.localData.urlKey}/faq` : '/faq' },
        { title: t('terms'), path: itin ? `/${itin?.localData.urlKey}/terms` : '/terms' },
        { title: t('about_us'), path: itin ? `/${itin?.localData.urlKey}/about` : '/about' },
      ],
      signOutMenu
    ];
    if (!isUserAuthenticated) {
      menuItems.pop()
    }

    const onKeyPressed = (event, type, path) => {
      if (event.key === 'Enter' && type !== 'itinerary') {
        navigate(path)
      }
    }

    const postSignOutActions = () => {
      setIsLoading(false);
      navigate(`/login/1`, { replace: true });
      setItin(null);
    };

    const onSignOutClick = async () => {
      setIsLoggingOut(true);
      if (!isUserAuthenticated) return;
      setIsLoading(true);

      const { res, data, error } = await logOutUser()
      if (!res.ok || error) {
        setIsLoggingOut(false);
        setIsLoading(false);
        setGlobalModalContent(() => ({
          closable: true,
          message: res?.status === 404 ? t("auth_error_sign_out") : error ?? data?.error,
          buttons: [{ text: t('ok'), type: 'close' }]
        }));
        if (res?.status === 401) {
          await signOutError();
          postSignOutActions();
        }
        throw new Error(`Logout error: Res: ${res.status}, Error: ${error ?? data?.error}, Data: ${JSON.stringify(data)}`);
      }

      await signOutManual();
      postSignOutActions();
    }

    const checkItineraries = async (loginsData) => {
      const storedItins = await db.itineraries
        .orderBy('lastLogin')
        .reverse()
        .toArray();

      //for removing items from the indexed db that are not in the response
      if (storedItins.length === 0 && loginsData?.all.length === 0) {
        setGlobalModalContent(null);
        navigate(`/login/1`, { replace: true });
        return;
      }

      const { deleteIds, filteredDbItinerariesWithoutNested } = getDeletedItineraryIds(storedItins, loginsData);
      await db.itineraries.bulkDelete(deleteIds);

      const handleNewCurrentItinerary = (operatorCode, passcode, vamoosId, type) => {
        const authUserClientId = storedItins.find(itin => itin.vamoosId === vamoosId)?.client?.id;
        if (operatorCode && passcode) {
          setLoginDetails({
            operatorCode,
            passcode,
            redirect: true,
            type,
            clientId: authUserClientId ?? null,
            phase: !!storedItins.find(itin => itin.vamoosId === vamoosId) ? 'refresh' : 'initial',
          });
        } else {
          navigate(`/login/1`, { replace: true });
          setLoginDetails(null);
          setNavMenuItems(null);
          setCustomHistory([]);
          setItin(null);
        }
    }

      if (filteredDbItinerariesWithoutNested.length !== 0) {
        const storedNonNestedItineraries = storedItins.filter(x => x.parentVamoosId === null || !x.parentVamoosId);
        // if current itinerary is not in login list, then set new login (default) as current itinerary
        if (filteredDbItinerariesWithoutNested.some(obj => obj.vamoosId === storedNonNestedItineraries[0].vamoosId)) {
          const { operatorCode, passcode, vamoosId } = getNewCurrentItinerary(filteredDbItinerariesWithoutNested[0]?.vamoosId, loginsData);
          handleNewCurrentItinerary(operatorCode, passcode, vamoosId, 'replace');
        }
        setGlobalModalContent(null);
        return;
      }
      //setLoginDetails depending on URL
      let itinerary;
      let redirect = false;
      const keyParts = key?.split('-');
      let [vamoosId, nestedId] = keyParts || [undefined, undefined];

      if (!vamoosId && itemOfHamburgerMenu) {
        itinerary = storedItins[0];
      }

      if (vamoosId?.match(/^\d+$/)) {
        vamoosId = parseInt(vamoosId);

        if (nestedId?.match(/^\d+$/)) {
          nestedId = parseInt(nestedId);
          itinerary = storedItins.find(x => (x.vamoosId === nestedId && x.parentVamoosId === vamoosId));
        }

        if (!itinerary) {
          itinerary = storedItins.find(x => (x.vamoosId === vamoosId && x.parentVamoosId === null));
        }
      }

      if (!itinerary) {
        itinerary = storedItins.find(x => x.parentVamoosId === null);
        redirect = true;
      }

      //to setLoginDetails to most current itin when operator adds first itinerary to user profile
      if (!itinerary && isUserAuthenticated && loginsData?.all?.length !== 0) {
        const { operatorCode, passcode, vamoosId } = getNewCurrentItinerary(null, loginsData);
        handleNewCurrentItinerary(operatorCode, passcode, vamoosId, 'loading');
        return;
      }

      let { operatorCode, passcode, referenceCode, parentVamoosId, vamoosId: id, client  } = itinerary;
      if (isUserAuthenticated) {
        const foundItinerary = loginsData?.all.find(login => login.id === id);
        if (foundItinerary) passcode = foundItinerary.referenceCode;
      }

      setLoginDetails({
        operatorCode,
        passcode: parentVamoosId !== null ? referenceCode : passcode || referenceCode,
        parentVamoosId,
        redirect,
        type: 'refresh',
        clientId: client?.id ?? null,
        phase: 'refresh',
      });
      setGlobalModalContent(null);
    }

    const onClickRefreshForAuthUser = async () => {
      setGlobalModalContent(() => ({
        closable: false,
        children: (<div className="login-modal-content">
          <Spinner type="small" />
          <span>
          {
            t('update_checking')
          }
        </span>
        </div>)
      }));
      const { res, data, error } = await getUserInformation();
      if (!res.ok || error) {
        setGlobalModalContent(() => ({
          closable: true,
          message: error || data?.error[0]?.message || data?.error || t("auth_error_get_profile_info"),
          buttons: [{ text: t('ok'), type: 'close' }]
        }));
          if (res?.status === 401) {
            await signOutError();
            postSignOutActions();
          }
        throw new Error(`Get user information error: Res: ${res.status}, Error: ${error || data?.error[0]?.message || data?.error}, Data: ${JSON.stringify(data)}`);
      }
      setUserAuthInfo(data);
      setLastUserAuthInfoRequestTime(Date.now());
      await checkItineraries(data.logins);
    }

    const renderMenuItems = (menuItemGroup) => {
      return (
        <div>
          {menuItemGroup[0].type === 'sign-out' ? <Divider /> : null}
          <List>
            {menuItemGroup.map(({ type, title, operatorCode, passcode, path, clientId, vamoosId }, i) => (
              <ListItemButton
                className={type === 'itinerary' ? 'itinerary-item' : ''}
                onClick={() => {
                  if (type === 'itinerary') {
                    setLoginDetails({
                      operatorCode,
                      passcode,
                      redirect: true,
                      type: 'manual',
                      clientId,
                      phase: isUserAuthenticated
                        ? !!storedNonNestedItineraries.find(itin => itin.vamoosId === vamoosId) ? 'refresh' : 'initial'
                        : 'refresh',
                    });
                  } else if (type === 'sign-out') {
                    onSignOutClick();
                  } else if (type === 'refresh') {
                    if (isUserAuthenticated) {
                      setLoginDetails(null);
                      onClickRefreshForAuthUser();
                    } else if (storedNonNestedItineraries.length !== 0) {
                      setLoginDetails({
                        operatorCode: storedNonNestedItineraries[0].operatorCode,
                        passcode: storedNonNestedItineraries[0].passcode || storedNonNestedItineraries[0].referenceCode,
                        redirect: false,
                        type: 'loading',
                        clientId: storedNonNestedItineraries[0].client?.id,
                      })
                    } else {
                      return null;
                    }
                  }
                  toggleMenu(false);
                }}
                key={`hamburger-menu-item-${i}-${title}`}
                component={type ? null : Link}
                to={path ?? null}
                onKeyDown={(event) => onKeyPressed(event, type, path)}
              >
                <ListItemText primary={composerData?.settings?.uppercased_menu_names ? title.toUpperCase() : title} />
              </ListItemButton>
            ))}
          </List>
          {menuItemGroup[0].type !== 'sign-out' ? <Divider /> : null}
        </div>
      );
    }

    return (
      <SwipeableDrawer
        className={`hamburger-menu hamburger-menu-${open ? 'active' : 'inactive'}`}
        anchor={viewMode === 'desktop' ? 'right' : 'left'}
        open={isOpen}
        onClose={toggleMenu(false)}
        onOpen={toggleMenu(true)}
      >
        <div className="boxes">
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleMenu(false)}
            onKeyDown={toggleMenu(false)}
          >
            {menuItems.slice(0, 3).map((menuItemGroup, i) => (
              <div key={`hamburger-menu-item-group-${i}`}>
                {renderMenuItems(menuItemGroup)}
              </div>
            ))}
          </Box>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleMenu(false)}
            onKeyDown={toggleMenu(false)}
          >
            {isUserAuthenticated ? renderMenuItems(menuItems[3]) : null}
          </Box>
        </div>
      </SwipeableDrawer>
    )
  };
  if (isLoading) return <LoadingScreen />;

  return (
    <div className="hamburger-menu-container">
      <Button
        className={`hamburger-btn ${hamburgerBtnClassName}`}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={toggleMenu(true)}
        disableRipple={viewMode === 'mobile'}
        disableTouchRipple={viewMode === 'mobile'}
        focusRipple={!(viewMode === 'mobile')}
        disableElevation={false}
      >
        <MenuIcon sx={{ fontSize: 35 }} />
      </Button>
      {renderMenu()}
    </div>
  );
}

export default HamburgerMenu;
