import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import './index.scss';
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Context from '../../context';
import { deleteUserAccount, deleteUserEmail, getUserInformation, logOutUser } from "../../libraries/api";
import useSignOut from "../../hooks/useSignOut";
import Container from "../global/Container";
import Spinner from "../global/Spinner";
import Button from "../global/Buttons/Button";
import AddEmailModal from "./AddEmailModal";
import ModalDefault from "../global/Modal/ModalDefault";
import { PLATFORM, VERSION_ID } from "../../helpers/constants";

const Settings = () => {
  const {
    itin, setItin,
    viewMode,
    t,
    customHistory,
    navMenuItems,
    setGlobalModalContent,
    isUserAuthenticated,
    userAuthInfo, setUserAuthInfo,
    setIsLoggingOut,
    setLastUserAuthInfoRequestTime,
  } = useContext(Context);

  const [closePath, setClosePath] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [openAddEmailModal, setOpenAddEmailModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState({ open: false, item: "" });

  const navigate = useNavigate();
  const signOutAuto = useSignOut('auto');
  const signOutManual = useSignOut('manual');
  const signOutError = useSignOut('error');

  useEffect(() => {
    let newClosePath = null;

    let i = 0;
    let target;
    while (true) {
      target = customHistory[customHistory.length + i - 1];
      if (!itin) {
        newClosePath = `/login`;
        break;
      }
      if (!target) {
        newClosePath = `/${itin.localData.urlKey}`;
        break;
      }
      if (!target.match(new RegExp(`^/${itin.localData.urlKey}/settings`, 'i'))) {
        newClosePath = i;
        break;
      }
      i--;
    }

    setClosePath(newClosePath);
  }, [customHistory, itin, viewMode]);

  useEffect(() => {
    if (spinner) {
      return () => {
        setSpinner(false);
      };
    }
  }, [spinner]);

  const errorHandling = (errorMessage, consoleMessage, resStatus, data, error) => {
    if (!errorMessage) errorMessage = error ?? ((data?.error?.length && data?.error[0]?.message) || data?.error) ?? t('error_api_unknown', { lng: itin?.language });
    setSpinner(false);
    setGlobalModalContent(() => ({
      closable: true,
      message: errorMessage,
      buttons: [{ text: t('ok'), type: 'close' }]
    }));
    throw new Error(`${consoleMessage}: Res: ${resStatus}, Error: ${errorMessage}, Data: ${JSON.stringify(data)}`);
  };

  const onSignOut = async () => {
    setIsLoggingOut(true);
    setSpinner(true);

    const { res, data, error } = await logOutUser()
    if (!res.ok || error) {
      setIsLoggingOut(false);
      const errorMessage = res?.status === 404
        ? t("auth_error_sign_out")
        : error ?? ((data?.error?.length && data?.error[0]?.message) || data?.error)
      errorHandling(errorMessage, 'Logout error', res.status, data);
    }

    navigate(`/login/1`, { replace: true });
    await signOutManual();

    setSpinner(false);
    setItin(null);
  };

  const deleteAccount = async () => {
    setConfirmModal({ open: false, item: "" });

    try {
      const { res, data, error } = await deleteUserAccount()
      if (!res.ok || error) {
        // eslint-disable-next-line no-throw-literal
        throw { modalMessage: error || (data?.error?.length && data?.error[0]?.message) || data?.error || "Deleting account failed" };
      }

      navigate(`/login/1`, { replace: true });
      await signOutAuto();

      setItin(null);
    } catch (e) {
      console.error(e);
      setGlobalModalContent(() => ({
        closable: true,
        message: e.modalMessage || e?.message || "Deleting account failed",
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
    } finally {
      setSpinner(false);
    }
  };

  const deleteEmail = async () => {

    try {
      const { res, data, error } = await deleteUserEmail({ email: confirmModal?.item })
      if (!res.ok || error) {
        if (res?.status === 401) {
          navigate(`/login/1`, { replace: true });
          await signOutError();
          setItin(null);
        }
        // eslint-disable-next-line no-throw-literal
        throw { modalMessage: error || (data?.error?.length && data?.error[0]?.message) || data?.error || "Deleting email failed" };
      }

      const { res: userInfoRes, data: userInfoData, error: userInfoError } = await getUserInformation();
      if (!userInfoRes?.ok || userInfoError) {
        setUserAuthInfo(prevState => ({
          ...prevState,
          emails: prevState?.emails.filter(email => email !== confirmModal?.item)
        }));
        if (userInfoRes?.status === 401) {
          navigate(`/login/1`, { replace: true });
          await signOutError();
          setItin(null);
        }
      } else {
        setUserAuthInfo(userInfoData);
        setLastUserAuthInfoRequestTime(Date.now());
      }
    } catch (e) {
      console.error(e);
      setGlobalModalContent(() => ({
        closable: true,
        message: e.modalMessage || e?.message || "Deleting email failed",
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
    } finally {
      setSpinner(false);
      setConfirmModal({ open: false, item: "" });
    }
  };

  const renderAdditionalEmails = () => {
    return (
      <>
        {userAuthInfo?.emails
          ?.filter(email => email !== userAuthInfo.primaryEmail)
          .map((email, index) => (
            <div className="emails" key={index}>
              <div className="text-content content">
                {email}
              </div>
              <IconButton
                aria-label="Remove email"
                onClick={() => {
                  setConfirmModal({ open: true, item: email })
                }}
              >
                <RemoveCircleIcon color='error' />
              </IconButton>
            </div>
          ))}
      </>
    )
  };

  const renderAccountSettings = () => {
    return (
      <div className={`settings-tile tile-grid-tile`}>
        <div className="text-content">
          <div className="title">
            {t('settings_user_profile_header')}
          </div>
          <div
            className="content">{t('auth_logged_email_desc_settings').replace('%s', localStorage.getItem("userEmail"))}</div>
        </div>
        <div className="buttons">
          <Button
            onClick={() => {
              onSignOut();
            }}
          >
            {t('menu_sign_out')}
          </Button>
          <Button
            onClick={() => {
              setConfirmModal({ open: true, item: "account" })
            }}
          >
            {t('delete_account_button_title')}
          </Button>
        </div>
        <div className="text-content content border-bottom">{t('settings_additional_emails_description')}</div>
        {renderAdditionalEmails()}
        <Button
          className="center-button"
          onClick={() => setOpenAddEmailModal(true)}
        >
          {t('add_email_to_user_profile')}
        </Button>
      </div>
    )
  };

  const renderAppInfo = () => {
    return (
      <div className={`settings-tile tile-grid-tile`}>
        <div className="text-content">
          <div className="title">
            {/*TODO* add translation*/}
            App info
          </div>
          <div className="content">
            {/*TODO* add translation*/}
            <span>Version: {`${VERSION_ID.toString().slice(0, 1)}.${VERSION_ID.toString().slice(1, 3)}.${VERSION_ID.toString().slice(3)}`}</span>
            <span>Platform: {PLATFORM}</span>
            <span>User Agent: {navigator.userAgent}</span>
          </div>
        </div>
      </div>
    )
  };

  const renderConfirmModal = () => {
    return (
      <ModalDefault
        isOpen={confirmModal.open}
        onBackdropClick={() => setConfirmModal({ open: false, item: "" })}
        className="modal-global"
        header={t('delete_itinerary_title')}
        message={confirmModal.item === "account" ? t('delete_account_alert_message') : t('remove_email_alert_message')}
        buttons={[
          <button key={0} onClick={() => setConfirmModal({ open: false, item: "" })}>{t('cancel')}</button>,
          <button key={1} onClick={() => {
            setSpinner(true);
            confirmModal.item === "account" ? deleteAccount() : deleteEmail();
          }}>{t('confirm')}</button>
        ]}
        loading={spinner}
      />
    )
  };

  return (
    <Container
      className={`container-settings container-settings-${viewMode}`}
      mainElementClassName={`settings settings-${viewMode}`}
      barebonesMode={!navMenuItems}
      closePath={closePath}
    >
      {spinner && (
        <div className="center-spinner">
          <Spinner />
        </div>
      )}
      {isUserAuthenticated && renderAccountSettings()}
      {renderAppInfo()}
      {
        <AddEmailModal
          openModal={openAddEmailModal}
          setOpenAddEmailModal={setOpenAddEmailModal}
          setSpinner={setSpinner}
          errorHandling={errorHandling}
          spinner={spinner}
          finalStep="step2"
        />
      }
      {renderConfirmModal()}
    </Container>
  );
};

export default Settings;


