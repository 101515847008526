import StringsDA from "../translations/strings.da.json";
import StringsDE from "../translations/strings.de.json";
import StringsEN from "../translations/strings.en.json";
import StringsNB from "../translations/strings.nb.json";
import StringsES from "../translations/strings.es.json";
import StringsFR from "../translations/strings.fr.json";
import StringsFI from "../translations/strings.fi.json";
import StringsNL from "../translations/strings.nl.json";
import StringsSV from "../translations/strings.sv.json";

export const getOrdinal = date => {
  const day = date.getDate();
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const linksAndNewLinesToHtml = text => {
  if (text) {
    const urlRegex = /(\b(((https?|ftp|file|):\/\/)|www[.])[-A-Z0-9+&@#/%?=~_|!:,.;']*[-A-Z0-9+&@#/%=~_|])/ig;
    return text
      .replace(urlRegex, (url, b, c) => {
        const url2 = (c === 'www.') ? `http://${url}` : url;
        return `<a href="${url2}" target="_blank" rel="noopener">${url}</a>`;
      })
      .split('\n')
      .join('<br />');
  }
}

export const getLanguages = () => {
  const languages = [];
  const taken = {};

  for (let lang of navigator.languages) {
    lang = lang.substring(0,2);
    if (taken[lang]) continue;
    taken[lang] = true;
    languages.push(lang);
  }

  if (!taken['en']) languages.push('en');

  return languages;
};

export const generateDeviceTimezoneStr = () => {
  let timezone = 'GMT ';
  let tzOffsetMins = -new Date().getTimezoneOffset();
  timezone += tzOffsetMins < 0 ? '-' : '+';
  tzOffsetMins = Math.abs(tzOffsetMins);
  const hours = Math.floor(tzOffsetMins/60);
  timezone += `${hours < 10 ? '0' : ''}${hours}:`;
  const mins = Math.floor(((tzOffsetMins/60) % 1) * 60);
  timezone += `${mins < 10 ? '0' : ''}${mins}`;
  return timezone;
};

export const getTzOffset = (date, timezone) => {
  if (!timezone) return 0;
  // Legacy version.  Non-geographical
  if (timezone.includes("GMT ")) {
    let offset = 0;
    let timezone_array = timezone.replace(' ', '').split("GMT");
    const offset_array = timezone_array[1].split(":");
    const sign = offset_array[0].charAt(0);
    if (sign === '+') offset = (parseInt((offset_array[0])) + (offset_array[1]/60));
    if (sign === '-') offset = (parseInt((offset_array[0])) - (offset_array[1]/60));
    return offset;
  }
  // Geographical
  let a = date.toLocaleString("en-US", { timeZone: timezone }).replace(',', '').split(/[/\s:]/);
  // ICU issue; if ICU is NOT installed into Node (default off on nodesource for Debian)
  // Any locale will then result in a US style date, so accept this and transform manually
  if (a[6] && a[6] === 'AM' && a[3] === '12') {
    a[3] = 0;
  }
  if (a[6] && a[6] === 'PM' && a[3] < 12) {
    a[3] = parseInt(a[3]) + 12;
  }
  a = [ a[2], a[0] - 1, a[1], a[3], a[4], a[5]];

  const t1 = Date.UTC.apply(null, a);
  const t2 = new Date(date).setMilliseconds(0);
  return (t1 - t2) / 60 / 60 / 1000;
}

export const generateTranslationOptions = () => {
  const options = {
    partialBundledLanguages: true,
    interpolation: { escapeValue: false },
    lng: '',
    languages: '',
    resources: {
      da: {
        strings: StringsDA
      },
      de: {
        strings: StringsDE
      },
      en: {
        strings: StringsEN
      },
      nb: {
        strings: StringsNB
      },
      es: {
        strings: StringsES
      },
      fr: {
        strings: StringsFR
      },
      fi: {
        strings: StringsFI
      },
      nl: {
        strings: StringsNL
      },
      sv: {
        strings: StringsSV
      },
    },
  };

  const languages = getLanguages();
  options.languages = languages;

  for (let lang of languages) {
    if (!options.resources[lang]) continue;
    options.fallbackLng = lang;
    break;
  }

  return options;
};

export const removeTrailingWhiteSpaces = str => str.replace(/^\s+/g, '');

export const toggleAllPoiCategoryAndPoiType = (globalMapSettings, isActive) => {
  const poiTypeValues = ['track', 'route'];
  const categories = { ...globalMapSettings.poiCategories };
  const newPois = { ...globalMapSettings.pois };
  for (const category in categories) {
    categories[category].active = isActive;
  }
  const poiType = Object.values(globalMapSettings.pois).filter(x => poiTypeValues.includes(x.type));
  for (const type of poiType) {
    newPois[type.id].active = isActive;
    for (const poi of newPois[type.id].pois) {
      poi.active = isActive;
    }
  }
  return { newPois, categories };
};

export const sortCategories = (categories, itin) => {
  const poiTypeId = [itin.tracks[0]?.iconId, itin.routes[0]?.iconId]
  const sortedCategories = [];

  // Filter out undefined
  const filteredPoiTypeId = poiTypeId.filter(id => id);

  // Find categories with matching poiTypeIds and push them to the sortedCategories array
  filteredPoiTypeId.forEach(id => {
    const category = categories[id];
    if (category) {
      sortedCategories.push(category);
    }
  });

  // Sort the remaining categories by count in descending order
  const remainingCategories = Object.values(categories).filter(category => !filteredPoiTypeId.includes(category.id));
  remainingCategories.sort((a, b) => b.count - a.count);

  sortedCategories.push(...remainingCategories);
  return sortedCategories;
};

export const setMenuTitle = (data) => {
  let menuTitle = data.type === 'inspiration'
    ? data.overlay?.label ?? data.label
    : `${data.destination || ''} ${data.holidayType || ''}`;

  menuTitle = menuTitle.trim() ||
    `${data.operatorCode} - ${data.passcode || data.referenceCode}`;

  return menuTitle;
};

export const getNewCurrentItinerary = (id, logins) => {
  if (!logins || logins?.all.length === 0) return { operatorCode: null, passcode: null };
  const useDefaultLogin = id !== logins.default.id;
  const currentItinerary = useDefaultLogin
    ? logins.default
    : id !== logins.all[0].id
      ? logins.all[0]
      : logins.all[1];

  const operatorCode = currentItinerary?.operatorCode;
  const passcode = currentItinerary?.referenceCode;
  const vamoosId = currentItinerary?.id;
  return { operatorCode, passcode, vamoosId };
};

export const getDeletedItineraryIds = (itineraries, loginsData) => {
  //filter itins that are in indexedDb but not in user logins
  const filteredDbItineraries = itineraries.filter(itin => !loginsData?.all.some(login => login.id === itin.vamoosId));
  //remove nested itineraries from filteredArray
  const filteredDbItinerariesWithoutNested = filteredDbItineraries.filter(itin => itin.parentVamoosId === null);

  //avoid deleting nested itins if parent itin are not deleted
  const parentVamoosIds = [...new Set(filteredDbItineraries
    .filter(itin => itin.parentVamoosId !== null)
    .map(itin => itin.parentVamoosId))];

  const deleteItineraries = [];
  filteredDbItinerariesWithoutNested.forEach(itinWithoutNested => {
    const isParentIncluded = parentVamoosIds.includes(itinWithoutNested.vamoosId);
    if (isParentIncluded) {
      const childItineraries = filteredDbItineraries.filter(itin => itin.parentVamoosId === itinWithoutNested.vamoosId);
      deleteItineraries.push(...childItineraries);
    }
    deleteItineraries.push(itinWithoutNested);
  });
  const deleteIds = deleteItineraries.map(x => x.id);

 return { deleteIds, filteredDbItinerariesWithoutNested };
};

export const isValidEmail = email => {
  //eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};