import React, { useContext, useEffect } from "react";
import Context from "../../context";
import './SignIn.scss';
import { logEvent } from "firebase/analytics";
import { useAnalytics } from "../GoogleAnalytics";

const SignInApple = ({ handleSignIn, setSpinner, state, nonce }) => {
  const {
    composerData,
    setGlobalModalContent,
    t
  } = useContext(Context);
  const analytics = useAnalytics();

  useEffect(() => {
    window.AppleID.auth.init({
      clientId: composerData?.auth?.apple?.systemClientId,
      scope: 'name email',
      redirectURI: `${window.location.origin}/auth/callback/${composerData?.auth?.apple?.name}`,
      state: state,
      nonce: nonce,
      usePopup: true
    });

  }, [nonce, state, composerData?.auth?.apple?.name, composerData?.auth?.apple?.systemClientId])

  useEffect(() => {
    const handleAppleSignInSuccess = async (event) => {
      setSpinner(true);
      if (event.detail.authorization.state !== state) {
        setGlobalModalContent(() => ({
          closable: true,
          message: t("auth_error_state_mismatch"),
          buttons: [{ text: t('ok'), type: 'close' }]
        }));
        setSpinner(false);
        return;
      }

      const logInUserBody = {
        method: composerData?.auth?.apple?.name,
        clientId: composerData?.auth?.apple?.systemClientId,
        idToken: event.detail.authorization.id_token,
        code: event.detail.authorization.code,
        nonce: nonce,
        ...(event.detail.user && { profile: event.detail.user }),
      }
      await handleSignIn('apple', logInUserBody, null);
    };

    const handleAppleSignInFailure = async (event) => {
      setSpinner(true);
      console.error("Sign in Apple error: ", event.detail.error);
      localStorage.removeItem("state");
      localStorage.removeItem("nonce");
      setSpinner(false);
    };

    document.addEventListener('AppleIDSignInOnSuccess', handleAppleSignInSuccess);
    document.addEventListener('AppleIDSignInOnFailure', handleAppleSignInFailure);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleAppleSignInSuccess);
      document.removeEventListener('AppleIDSignInOnFailure', handleAppleSignInFailure);
    };
  }, [handleSignIn, composerData?.auth?.apple?.name, nonce, state, composerData?.auth?.apple?.systemClientId, setGlobalModalContent, t, setSpinner]);

  const handleAppleSignIn = () => {
    window.AppleID.auth.signIn();
  }

  return (
    <>
      <button className="apple-sign-in-button"
              onClick={() => {
                if (!!analytics) logEvent(analytics, 'sso_login_start', { login_id: 'apple' });
                handleAppleSignIn();
              }}>
        <img className="apple-sign-in-button-icon" src="/images/apple.svg" alt="Apple Logo" />
        <span className="apple-sign-in-button-text">{t('auth_sign_in_apple')}</span>
      </button>
    </>
  )
}

export default SignInApple;
