import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './AddEmailModal.scss';
import Context from "../../context";
import ModalCustom from "../global/Modal/ModalCustom";
import TextInput from "../global/TextInput";
import Button from "../global/Buttons/Button";
import { isValidEmail } from "../../helpers";
import { addUserEmail, getUserInformation, shareItinerary, verifyUserEmail } from "../../libraries/api";
import OtpInput from "../global/OtpInput";
import { logEvent } from "firebase/analytics";
import { useAnalytics } from "../GoogleAnalytics";
import useSignOut from "../../hooks/useSignOut";

const AddEmailModal = ({ openModal, setOpenAddEmailModal, spinner, setSpinner, errorHandling, finalStep }) => {
  const { viewMode, t, setGlobalModalContent, setUserAuthInfo, setLastUserAuthInfoRequestTime, itin, setItin } = useContext(Context);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState("step1");

  const { pathname } = useLocation();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const signOutError = useSignOut('error');

  const modalContent = {
    step1: finalStep === "step2"
      ? { content1: "", content2: t("email_verification_step1_description") }
      : { content1: "", content2: t("itinerary_share_enter_email_description") },
    step2: { content1: t("email_verification_step2_headline"), content2: t("email_verification_step2_description") },
    stepSuccess: {
      content1: t("itinerary_share_confirmation_title"),
      content2: t("itinerary_share_confirmation_description").replace('%@', email).split('\\n').map((item, key) => (
        <span key={key}>{key === 1 ? <strong>{item}</strong> : item}<br /></span>
      ))
    }
  }

  const errorHandlingDefault = (errorMessage, consoleMessage, resStatus, data, error) => {
    if (!errorMessage) errorMessage = error ?? ((data?.error?.length && data?.error[0]?.message) || data?.error) ?? t('error_api_unknown', { lng: itin?.language });
    setSpinner(false);
    setGlobalModalContent(() => ({
      closable: true,
      message: errorMessage,
      buttons: [{ text: t('ok'), type: 'close' }]
    }));
    throw new Error(`${consoleMessage}: Res: ${resStatus}, Error: ${errorMessage}, Data: ${JSON.stringify(data)}`);
  };

  const isValidCode = val => {
    const codeRegex = /^(100000|[1-9]\d{5}|999999)$/;
    return codeRegex.test(val);
  };

  const onClose = () => {
    setOpenAddEmailModal(false);
    setEmail("");
    setCode("");
    setStep("step1");
  };

  const backToStepOne = () => {
    if(step === "step2") {
      setCode("");
      setStep("step1");
    } else {
      onClose();
    }
  };

  const onChange = value => {
      setEmail(value.replace(/\s/g, ''));
  };

  const onKeyPressed = (event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  }

  const onAddEmail = async () => {
    setSpinner(true);

    if (finalStep === "step2") {
      const { res, data, error } = await addUserEmail({ email })
      if (!res?.ok || error) {
        const errorHandlingToUse = errorHandling || errorHandlingDefault;
        errorHandlingToUse(null, 'Add email error', res?.status, data, error);
      }
      if (data?.alreadyAdded) {
        setGlobalModalContent(() => ({
          closable: true,
          message: t('email_already_added_message'),
          buttons: [{ text: t('ok'), type: 'close' }]
        }));
        return;
      }
    } else {
      const { operatorCode, passcode, referenceCode } = itin?.localData;
      const { res, data, error } = await shareItinerary(operatorCode, passcode || referenceCode, { email })
      if (!res?.ok || error) {
        const errorHandlingToUse = errorHandling || errorHandlingDefault;
        errorHandlingToUse(null, 'Share itinerary error', res?.status, data, error);
      }
    }
    setStep(finalStep);
    setSpinner(false);
  };

  const onVerifyEmail = async () => {
    setSpinner(true);

    try {
      const codeNum = parseInt(code);
      const { res, data, error } = await verifyUserEmail({ email, code: codeNum })
      if (!res.ok || error) {
        if (res?.status === 401) {
          navigate(`/login/1`, { replace: true });
          await signOutError();
          setItin(null);
        }
        const localisedError = data?.error === 'Verification code invalid' ? t("error_incorrect_email_validation_code") : data?.error;
        // eslint-disable-next-line no-throw-literal
        throw { modalMessage: localisedError || error || (data?.error?.length && data?.error[0]?.message) || t("error_api_unknown") };
      }
      if (!!analytics) logEvent(analytics, 'sso_login_add_email', { login_id: localStorage.getItem("loginId") });

      const { res: userInfoRes, data: userInfoData, error: userInfoError } = await getUserInformation();
      if (!userInfoRes.ok || userInfoError) {
        if (userInfoRes?.status === 401) {
          navigate(`/login/1`, { replace: true });
          await signOutError();
          setItin(null);
        }
        // eslint-disable-next-line no-throw-literal
        throw { modalMessage: userInfoError || userInfoData?.error[0]?.message || userInfoData?.error || t("auth_error_get_profile_info") };
      }

      setUserAuthInfo(userInfoData);
      setLastUserAuthInfoRequestTime(Date.now());
      onClose()

    } catch (e) {
      console.error(e);
      setGlobalModalContent(() => ({
        closable: true,
        message: e.modalMessage || e?.message || "Deleting account failed",
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
    } finally {
      setSpinner(false);
    }
  };

  return (
    <ModalCustom
      isOpen={openModal}
      onBackdropClick={onClose}
      onKeyDown={(event) => onKeyPressed(event)}
      content1={modalContent[step].content1}
      content2={modalContent[step].content2}
      confirmButton={
        <Button
          disabled={spinner || (step === "step2" ? !isValidCode(code) : !isValidEmail(email))}
          onClick={() => {
            step === "step1" ? onAddEmail()
              : step === "step2" ? onVerifyEmail()
              : onClose();
          }}>
          {step === "stepSuccess" ? t('ssl_error_continue') : t('proceed_button')}
        </Button>}
      cancelButton={step === "step1" && <button onClick={() => onClose()} className="cancel">{finalStep === "step2"? t('skip') : t('cancel')}</button>}
      onClose={onClose}
      loading={spinner}
      showBackButton={viewMode === "mobile" && (step === "step1" || step === "step2")}
      backButtonPath={pathname}
      backButtonFn={backToStepOne}
    >
      <div className="modal-input">
        {step === "step2"
          ? <OtpInput
            code={code}
            setCode={setCode}
            customBackground={viewMode === 'mobile'}
            isValidCode={isValidCode}
            onVerifyEmail={onVerifyEmail}
          />
          : step === 'stepSuccess' ? null
            : <TextInput
              key={step}
              value={email}
              onChange={e => onChange(e.target.value)}
              placeholder={t('login_form_email_hint')}
              onKeyUp={e => {
                if (e.code === 'Enter' && isValidEmail(email)) onAddEmail();
              }}
              autoFocus={true}
              customBackground={viewMode === 'mobile'}
            />
        }
      </div>
    </ModalCustom>
  );
};

export default AddEmailModal;