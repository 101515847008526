import React, { useContext, useEffect, useState } from 'react';
import Context from '../../context';
import './UserIdScreen.scss';
import SlideTemplate from "./SlideTemplate";
import { db } from "../../db";
import { VAMOOS_URL } from "../../helpers/constants";
import { getUserInformation } from "../../libraries/api";
import Spinner from "../global/Spinner";
import { useNavigate } from "react-router-dom";
import useSignOut from "../../hooks/useSignOut";

const UserIdScreen = ({ operatorCode, setOperatorCode, isActive, hideContent, id, submitFn }) => {

  const { t, composerData, isUserAuthenticated, userAuthInfo, showAppSpinner, isLoginRoute, globalModalContent, setGlobalModalContent, setUserAuthInfo, setLastUserAuthInfoRequestTime, setItineraryUpdate, viewMode, loginPrompt, setItin } = useContext(Context);

  const [isUserHasItineraries, setIsUserHasItineraries] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCustomTemplate, setShowCustomTemplate] = useState(false);
  const [additionalEmails, setAdditionalEmails] = useState(null);

  const navigate = useNavigate();
  const signOutError = useSignOut('error');

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    const getStoredItineraries = async () => {
      const storedItineraries = await db.itineraries.toArray();
      if (storedItineraries.length > 0 || (userAuthInfo?.logins?.all.length > 0 && !loginPrompt)) {
        if (isMounted) {
          setIsUserHasItineraries(true);
        }
      } else {setIsUserHasItineraries(false)}
      setIsLoading(false)
    };
    getStoredItineraries();

    return () => {
      isMounted = false;
      setIsLoading(false)
    };
  }, [loginPrompt, userAuthInfo?.logins?.all.length, setIsUserHasItineraries, isLoginRoute, globalModalContent]);

  useEffect(() => {
    // to avoid updating showCustomTemplate during loading
    if (!!globalModalContent) return;
    setShowCustomTemplate(isUserAuthenticated && isUserHasItineraries === false)
  }, [globalModalContent, isUserAuthenticated, isUserHasItineraries]);

  useEffect(() => {
    if (showCustomTemplate) {
      return () => {
        setShowCustomTemplate(false);
      };
    }
  }, [showCustomTemplate]);

  useEffect(() => {
    const emails = userAuthInfo?.emails?.filter(email => email !== userAuthInfo?.primaryEmail);
    if (emails?.length > 0) setAdditionalEmails(userAuthInfo?.emails);
  }, [userAuthInfo?.emails, userAuthInfo?.primaryEmail]);

  const handleRetryClick = async () => {
    setGlobalModalContent(() => ({
      closable: false,
      children: (<div className="login-modal-content">
        <Spinner type="small" />
        <span>
          {
            t('update_checking')
          }
        </span>
      </div>)
    }));

    const { res, data, error } = await getUserInformation();
    if (!res.ok || error) {
      setGlobalModalContent(() => ({
        closable: true,
        message: error || data?.error[0]?.message || data?.error || t("auth_error_get_profile_info"),
        buttons: [{ text: t('ok'), type: 'close' }]
      }));
      if (res?.status === 401) {
        navigate(`/login/1`, { replace: true });
        await signOutError();
        setItin(null);
      }
      throw new Error(`Get user information error: Res: ${res.status}, Error: ${error || data?.error[0]?.message || data?.error}, Data: ${JSON.stringify(data)}`);
    }
    setUserAuthInfo(data);
    setLastUserAuthInfoRequestTime(Date.now());

    if (data?.logins?.all?.length > 0) {
      setItineraryUpdate(data.logins.default);
    } else {
      setGlobalModalContent(null);
    }
  };

  return (
    <SlideTemplate
      className="user-id-screen"
      text1={showCustomTemplate ? t('welcome_screen_header').replace(/\\n/g, viewMode === "desktop" ? ' ' : '<br>') : (composerData?.strings?.login_text_1?.text ?? t('login_text_1'))}
      text2={showCustomTemplate ? localStorage.getItem("userEmail") ? `${t('logged_with_email')} <b>${localStorage.getItem("userEmail")}</b>` : '' : (composerData?.strings?.login_text_2?.text ?? t('login_text_2'))}
      text3={showCustomTemplate ? t('welcome_screen_step1').replace('%s.', '') : (composerData?.strings?.login_text_3?.text ?? t('login_text_3'))}
      text4={showCustomTemplate ? t('welcome_screen_step2') : (composerData?.strings?.login_text_4?.text ?? t('login_text_4'))}
      text5={showCustomTemplate && additionalEmails ? `${t('additional_profile_emails').replace('%s', additionalEmails.join(", "))}` : (composerData?.strings?.login_text_5?.text ?? t('login_text_5'))}
      text6={showCustomTemplate ? t('welcome_screen_step3') : ''}
      text7={showCustomTemplate ? t('welcome_screen_step4').replace("%s", `<a href=${VAMOOS_URL} target="_blank" rel="noreferrer">www.vamoos.com</a>`) : ''}
      inputList={[{
        key: 0,
        value: operatorCode,
        onChange: val => setOperatorCode(val),
        placeholder: composerData?.strings?.login_user_id_hint?.text || t('login_user_id_hint'),
        className: 'user-id-input',
        autoFocus: true,
      }]}
      button={{
        disabled: !operatorCode,
        text: composerData?.strings?.login_next_button?.text || t('login_next_button'),
      }}
      isActive={isActive}
      submitFn={() => submitFn(operatorCode)}
      hideContent={hideContent}
      id={id}
      loading={isLoading || showAppSpinner}
      setIsLoading={setIsLoading}
      showCustomTemplate={showCustomTemplate}
      handleRetryClick={handleRetryClick}
      authRequiredToLogin={composerData?.authenticationRequiredToLogIn}
    />
  );
};

export default UserIdScreen;