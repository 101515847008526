import { createContext, useContext, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import Context from "../../context";
import { differenceInSeconds } from 'date-fns';

const FirebaseContext = createContext(null);
const firebaseConfig = {
  apiKey: "AIzaSyBdulvkp4CEcCEsJtBz9AOIrzakaXnWZz4",
  authDomain: "webvamoos.firebaseapp.com",
  projectId: "webvamoos",
  storageBucket: "webvamoos.appspot.com",
  messagingSenderId: "986972825459",
  appId: "1:986972825459:web:5a7454ebb3febfe2258706",
  measurementId: "G-TRXYLCH910"
};

// USING GOOGLE FIREBASE ANALYTICS
let app;
let analytics;

const GoogleAnalyticsProvider = ({ children }) => {
  const { itin, previewMode } = useContext(Context);

  function getApp() {
    if (!app) {
      app = initializeApp(firebaseConfig);
    }
    return app;
  }

  useEffect(() => {
    analytics = getAnalytics(getApp());
  }, [itin]);


  useEffect(() => {
    if (!analytics || !itin) return;
    setUserProperties(analytics, { operatorcode: itin.operatorCode });

    if (previewMode) {
      setUserProperties(analytics, { viewmode: 'instantView' });
    } else {
      setUserProperties(analytics, { viewmode: 'basicView' });
    }

  }, [itin, previewMode]);

  useEffect(() => {
    if (!analytics || !itin) return;
    if (itin.type === "stay" && itin.localData?.arrivalDate === null) {
      setUserProperties(analytics, { phase: 'no-dates' });
      return;
    }

    const departureDate = new Date(itin.type === 'stay' ? itin.localData?.arrivalDate : itin.departureDate * 1000);
    const returnDate = new Date(itin.type === 'stay' ? itin.localData?.departureDate : itin.returnDate * 1000);
    const today = new Date();
    const todayAfterDepartureDate = (differenceInSeconds(today, departureDate) > 0)
    const todayAfterReturnDate = (differenceInSeconds(today, returnDate) > 0)

    switch (true) {
      case !todayAfterDepartureDate:
        setUserProperties(analytics, { phase: 'before' });
        break;

      case (todayAfterDepartureDate && !todayAfterReturnDate):
        setUserProperties(analytics, { phase: 'during' });
        break;

      case (todayAfterDepartureDate && todayAfterReturnDate):
        setUserProperties(analytics, { phase: 'after' });
        break;

      default:
        console.error("Incorrect dates")
    }
  }, [itin]);

  return (
    <FirebaseContext.Provider value={analytics}>
      {children}
    </FirebaseContext.Provider>
  );
};

const useAnalytics = () => useContext(FirebaseContext);

export { GoogleAnalyticsProvider, useAnalytics };
