import React, { useContext, useEffect, useCallback, useState } from "react";
import Context from "../../context";
import jwt_decode from "jwt-decode";
import './SignIn.scss';
import i18next from "i18next";
import { logEvent } from "firebase/analytics";
import { useAnalytics } from "../GoogleAnalytics";

const SignInGoogle = ({ handleSignIn, setSpinner, nonce }) => {
  const {
    composerData,
  } = useContext(Context);
  const analytics = useAnalytics();

  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);

  useEffect(() => {
    const googleScript = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');

    if (googleScript) {
      setIsGoogleScriptLoaded(true);
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setIsGoogleScriptLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleGoogleCredentialResponse = useCallback(async (response) => {
    setSpinner(true);
    const logInUserBody = {
      method: composerData?.auth.google.name,
      clientId: response.client_id,
      idToken: response.credential,
      nonce: nonce,
    }
    const decoded = jwt_decode(response.credential);

    await handleSignIn('google', logInUserBody, decoded.sub)

  }, [composerData?.auth?.google?.name, handleSignIn, setSpinner, nonce]);

  useEffect(() => {
    if (!composerData || !isGoogleScriptLoaded) return;
    let isMounted = true;

    window.google.accounts.id.initialize({
      client_id: composerData?.auth?.google?.systemClientId,
      callback: handleGoogleCredentialResponse,
      nonce: nonce,
    });

    const handleButtonClick = () => {
      if (!!analytics) logEvent(analytics, 'sso_login_start', { login_id: 'google' });
    };

    if (isMounted) {
      window.google.accounts.id.renderButton(
        document.getElementById("googleButton"),
        { theme: "outline", size: "medium", locale: i18next.language, click_listener: handleButtonClick }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [analytics, composerData, isGoogleScriptLoaded, handleGoogleCredentialResponse, nonce])



  return (
    <>
      <div id="googleButton" />
    </>
  )
}

export default SignInGoogle;
